/* eslint-disable no-console */
/* global __ENVIRONMENT__ */
import { init as initApm } from '@elastic/apm-rum';
let logger;

const startApm = () => {
  const environment =
    __ENVIRONMENT__ === 'prod' ? 'production' : __ENVIRONMENT__;
  try {
    const gasketData =
      typeof window === 'undefined'
        ? {
            apm_rum: {
              serverUrl: process.env.ELASTIC_APM_SERVER_URL,
              active: !!parseInt(process.env.APM_ENABLED, 10),
              serviceVersion: process.env.GIT_COMMIT || process.env.GASKET_ENV,
            },
          }
        : require('@gasket/data');

    logger = initApm({
      ...gasketData?.apm_rum,
      serviceName: 'security-ui',
      environment,
    });

    // pick the fields we want
    const userContext = {
      customerId: gasketData.visitor.customerId,
      hashedShopperId: gasketData.auth.hashedShopperId,
    };

    logger.setUserContext(userContext);
  } catch (e) {
    logger = {};
  }
};

if (process.env.NODE_ENV === 'test') {
  const end = () => {};
  logger = {
    error: console.log,
    startTransaction: () => ({ startSpan: () => ({ end }), end }),
  };
} else if (!logger && typeof window !== 'undefined') {
  startApm();
  logger.error = (...err) => {
    if (__ENVIRONMENT__ === 'local') {
      // you can set APM_ENABLED=0 in your .env to bypass capturing local errors
      logger.captureError(...err);
      console.error(...err);
    } else if (__ENVIRONMENT__ === 'test') {
      console.error(err);
    } else {
      logger.captureError(...err);
    }
  };
}

export { logger, startApm };
