/* eslint-disable no-undef */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createApp } from '@godaddy/gasket-next';
import NextApp from 'next/app';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { ErrorBoundary } from '@/components/custom-error';
import Config from '@/components/config';

import { WSSContextProvider } from '../packages/wss-context/src';

import '../common/initAPM';

// Retain this import to avoid
// a routing bug with loading scss files in other pages/components.
// @see: https://github.com/zeit/next-plugins/issues/282
import '../styles/securityui.scss';

// React query client.
const queryClient = new QueryClient();

const App = ({ Page, pageProps }) => {
  const [config] = useState({
    ...pageProps.flags,
    ...pageProps.serverConfig,
  });

  useEffect(() => {
    if (config.is_sucuri) {
      // eslint-disable-next-line handle-callback-err
      window.ux.header((err, header) => {
        // Hide the cart component for the Sucuri plids
        header.updateCartComponent(null);

        // Use sucuri side nav
        header.updateSidebarNav(config.nav_data?.sidebar);

        // Update the account tray nav
        header.updateAccountTrayNav(config.nav_data?.account_tray);
      });
    }
  }, [config]);

  return (
    <QueryClientProvider client={queryClient}>
      <WSSContextProvider>
        <ErrorBoundary>
          <Config value={config}>
            <Page {...pageProps} />
          </Config>
        </ErrorBoundary>
      </WSSContextProvider>
    </QueryClientProvider>
  );
};

App.getInitialProps = async (appContext) => {
  let flags = {};
  let serverConfig = {};
  const { req } = appContext.ctx;

  if (req) {
    flags = {
      ...req.config.flags,
      ...req.locals,
    };

    if (req.config.switchboard) {
      flags = { ...flags, ...req.config.switchboard };
    }

    serverConfig = {
      certs_site: req.config.certs_site,
      certs_site_resellers: req.config.certs_site_resellers,
      mwp_hosting_endpoint: req.config.mwp_hosting_endpoint,
      mwp_hosting_reseller_endpoint: req.config.mwp_hosting_reseller_endpoint,
      bff_api: req.config.bff_api,
      bff_api_reseller: req.config.bff_api_reseller,
      is_sucuri: req.config.is_sucuri,
      nav_data: req.config.nav_data,
      nux_onboarding_endpoint: req.config.nux_onboarding_endpoint,
      nux_onboarding_endpoint_reseller:
        req.config.nux_onboarding_endpoint_reseller,
    };

    flags = { ...flags };
  }

  const props = await NextApp.getInitialProps(appContext);
  return Promise.resolve({
    ...props,
    pageProps: {
      ...props.pageProps,
      flags: { ...flags },
      serverConfig: { ...serverConfig },
    },
  });
};

App.propTypes = {
  Page: PropTypes.func,
  pageProps: PropTypes.object,
};

export default createApp({ Layout: App });
