import axios from 'axios';
import { data as uxdata } from '@ux/data';

import { getBffCsrfToken } from '@/components/services/util';

import { determineBffURL } from '../../environment-utils';

const bffUrl = determineBffURL();

/**
 * Retrieve sites for the current user logged in.
 * @param {String} jwt JWT token for the current user logged in
 * @param {String} plid Private label ID
 * @returns {Promise<*>} Returns the promise of the request to the API
 */
export const getSites = (jwt, plid) => {
  const brand = plid ?? uxdata?.privateLabelId;
  let options = {
    timeout: 30 * 1000, // 30s
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (jwt) {
    // Accept the JWT token for the current user logged in.
    options = {
      headers: {
        ...options.headers,
        Authorization: `sso-jwt ${jwt}`,
      },
    };
  } else {
    // Accept the credentials (auth_idp) for the current user logged in.
    options.withCredentials = true;
  }

  // Sucuri (Using SecUI BFF api proxy)
  if (parseInt(brand, 10) === 565123) {
    const siteUrl = jwt
      ? `${bffUrl}/api/wss/sucuri/views/index`
      : '/api/bff/wss/sucuri/views/index';
    return axios.get(siteUrl, options);
  }

  // Get the context for the current user logged.
  return axios.get(`${bffUrl}/api/wss/sites/views/index`, options);
};

/**
 * Retrieve subscriptions for the current user logged in.
 * @param {String} jwt JWT token for the current user logged in
 * @param {String} plid Private label ID
 * @returns {Promise<*>} Returns the promise of the request to the API
 */
export const getPlans = (jwt, plid) => {
  const brand = plid ?? uxdata?.privateLabelId;
  let options = {
    timeout: 30 * 1000, // 30s
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (jwt) {
    // Accept the JWT token for the current user logged in.
    options = {
      headers: {
        ...options.headers,
        Authorization: `sso-jwt ${jwt}`,
      },
    };
  } else {
    // Accept the credentials (auth_idp) for the current user logged in.
    options.withCredentials = true;
  }

  // Sucuri (Using SecUI BFF api proxy)
  if (parseInt(brand, 10) === 565123) {
    const siteUrl = jwt
      ? `${bffUrl}/api/wss/subscriptions/views/available`
      : '/api/bff/wss/subscriptions/views/available';
    return axios.get(siteUrl, options);
  }

  // Get the context for the current user logged.
  return axios.get(`${bffUrl}/api/wss/subscriptions/views/available`, options);
};

/**
 * Delete site from WSS.
 * @param {*} subscriptionId WSS Subscriptions
 * @returns {Promise<*>} Returns the promise of the request to the API
 */
export const deleteSite = (subscriptionId) => {
  const options = {
    timeout: 30 * 1000, // 30s
    withCredentials: true,
    header: {
      'Content-Type': 'application/json',
    },
  };

  // Get the context for the current user logged.
  return axios.delete(
    `${bffUrl}/api/wss/sites/actions/delete/${subscriptionId}`,
    options,
  );
};

/**
 * Change the favourite status of a site by calling the API endpoint.
 * @param {string} domain The domain of the site.
 * @param {boolean} isFavourite Indicates the status of the site (favourite or not).
 * @returns {Promise<axios.AxiosResponse<any>>} Axios response.
 */
export const favouriteSite = async (domain, isFavourite) => {
  const brand = uxdata?.privateLabelId;
  const csrfToken = await getBffCsrfToken();

  const options = {
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'x-xsrf-token': csrfToken,
    },
  };

  const body = {
    domain,
    isFavourite,
  };

  // Sucuri (Using SecUI BFF api proxy)
  if (parseInt(brand, 10) === 565123) {
    const siteUrl = '/api/bff/wss/sites/actions/favourite';
    return axios.post(siteUrl, body, options);
  }

  return axios.post(`${bffUrl}/api/wss/sites/actions/favourite`, body, options);
};
