import { useQuery } from '@tanstack/react-query';

import { getSites, getPlans } from './query';

/**
 * WSS Subscriptions context hook.
 * @param {Array} sites Array of user sites
 * @returns {Array} Returns the query Array
 */
export const useSiteContext = (sites) => {
  const queryOptions = {
    queryKey: ['getSites'],
    queryFn: async () => {
      try {
        if (sites && sites.length) {
          return sites;
        }
        const { data } = await getSites();

        return data?.sites || [];
      } catch (error) {
        throw new Error('Failed to retrieve users sites.', error);
      }
    },
    refetchOnWindowFocus: false,
  };

  if (sites && sites.length) {
    queryOptions.initialData = sites;
  }

  return useQuery(queryOptions);
};

/**
 * Security Available Plans context hook.
 * @param {Array} plans Array of user plans
 * @returns {Array} Returns the query Array
 */
export const usePlanContext = (plans) => {
  const queryOptions = {
    queryKey: ['getPlans'],
    queryFn: async () => {
      try {
        if (plans && plans.length) {
          return plans;
        }

        const { data } = await getPlans();

        return data?.subscriptions || [];
      } catch (error) {
        throw new Error("Failed to retrieve user's plans.");
      }
    },
    refetchOnWindowFocus: false,
  };

  if (plans && plans.length) {
    queryOptions.initialData = plans;
  }

  return useQuery(queryOptions);
};
