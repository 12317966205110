import { data as uxdata } from '@ux/data';

/**
 * Returns the internal service URL for a given service.
 *
 * @param {Object} options - The options object.
 * @param {string} options.subdomain - The subdomain to use.
 * @param {boolean} options.hasStaging - Whether the service has a staging environment.
 *
 * @returns {string|null} The internal service URL or null when called serverside.
 */
export const getInternalClientSideServiceUrl = ({ subdomain, hasStaging }) => {
  if (typeof window === 'undefined') return null;

  const plid = uxdata.privateLabelId;

  let envPrefix = uxdata.env === 'prod' ? '' : `${uxdata.env}-`;
  const base = plid === 1 ? 'godaddy.com' : 'secureserver.net';

  // Account for services with staging environments
  if (hasStaging) {
    const currentUrl = new URL(window.location);
    envPrefix = currentUrl.hostname.includes('stg') ? 'stg-' : '';
  }

  return `https://${subdomain}.${envPrefix}${base}`;
};

/**
 * Determines the base URL for the BFF (Backend For Frontend) service.
 *
 * @returns {string} The base URL for the BFF service.
 *
 * @example
 * // When running in a browser environment with 'www.dev-sucuri.net' as the home URL
 * determineBffURL(); // Returns 'https://bff.pcx.dev-secureserver.net'
 *
 * @example
 * // When running in a Node.js environment with 'prod' as the environment
 * determineBffURL(); // Returns 'https://bff.pcx.secureserver.net'
 */
export const determineBffURL = () => {
  // Client check
  if (typeof window !== 'undefined') {
    return getInternalClientSideServiceUrl({
      subdomain: 'bff.pcx',
      hasStaging: true,
    });
  }

  // Server-side check
  // eslint-disable-next-line no-undef
  const env = __ENVIRONMENT__ === 'development' ? 'dev' : __ENVIRONMENT__;
  const prefix = env === 'prod' || env === 'production' ? '' : `${env}-`;
  return `https://bff.pcx.${prefix}secureserver.net`;
};
