import React from 'react';

export const WSSContext = React.createContext(
  // eslint-disable-next-line no-undefined
  undefined,
);

/**
 * React Component to provide the WSS Context.
 * @param {React.JSX} Children React Children
 * @returns {JSX.Element} Context Provider
 */
export const WSSContextProvider = ({ children }) => {
  return <WSSContext.Provider>{children}</WSSContext.Provider>;
};
