import { logger } from '../common/initAPM';
import eventIDs from '../common/events';

const eventNames = new Set(Object.values(eventIDs));

const getEid = (id) => {
  return `sec.secui.${id}`;
};

/**
 * Checks error message id for associated tracking event
 *
 * @param {string} msgID An event ID
 * @returns {string} tracking event to track
 */
const mapErrorMessageToEventId = (msgID) => {
  let trackingID;

  switch (msgID) {
    case 'provision_api__site_already_exists':
      trackingID = eventIDs.WSS_SETUP_FAILED_SITE_EXISTS;
      break;
    case 'provision_api__subscription_already_setup':
      trackingID = eventIDs.WSS_SETUP_FAILED_SUBSCRIPTION_IN_USE;
      break;
    case 'provision_api__invalid_domain':
      trackingID = eventIDs.WSS_SETUP_FAILED_INVALID_DOMAIN;
      break;
    case 'provision_api__invalid_subscription':
      trackingID = eventIDs.WSS_SETUP_FAILED_INVALID_SUBSCRIPTION;
      break;
    case 'provision_api__invalid_backups':
      trackingID = eventIDs.WSS_SETUP_FAILED_INVALID_BACKUPS;
      break;
    case 'provision_api_error_message':
      trackingID = eventIDs.WSS_SETUP_FAILED_ERROR_RETURNED;
      break;
    default:
      trackingID = eventIDs.WSS_SETUP_FAILED_UNKNOWN;
  }

  return trackingID;
};

/**
 * Sends analytics events via trfq
 *
 * @param {string} id An event ID
 * @param {string} desc An event description, usually "click"
 * @param {object} data Additional event key/value pairs
 * @param {object} event The underlying DOM event
 * @param {function} callback A callback to invoke after the event has been recorded
 */
// eslint-disable-next-line max-params
const trackEvent = (id, desc, data, event, callback) => {
  if (!eventNames.has(id)) {
    logger.error(`${id} is an invalid event id`);
    return;
  }

  desc = desc || '';
  event = event || {};
  callback = callback || function () {};

  const eid = getEid(id);

  window?._expDataLayer?.push({
    schema: 'add_event',
    version: 'v2',
    data: {
      eid: eid,
      type: desc,
      event_type: event,
      custom_properties: {
        ...data,
      },
    },
  });

  if (typeof callback === 'function') {
    return callback();
  }
};

module.exports = {
  trackEvent,
  events: eventIDs,
  eventNames,
  getEid,
  mapErrorMessageToEventId,
};
