import React from 'react';
import PropTypes from 'prop-types';

const ConfigContext = React.createContext();

const Config = ({ value, children }) => {
  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
};

Config.propTypes = {
  value: PropTypes.object,
  children: PropTypes.node,
};

export { ConfigContext, Config };
export default Config;
