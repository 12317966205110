const fetch = require('@gasket/fetch');
const { setupApi, makeFetchAdapter } = require('reduxful');

const apiDescription = {
  getSubscriptions: {
    method: 'GET',
    url: '/api/shopper/subscriptions',
    headers: {
      accept: 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
  },
  getDomains: {
    method: 'GET',
    url: '/api/meta/domains',
    headers: {
      accept: 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
  },
  createTicket: {
    method: 'POST',
    url: '/api/mssl/create',
    headers: {
      accept: 'application/json',
    },
    mode: 'cors',
    credentials: 'include',
  },
};

const options = {
  requestAdapter: makeFetchAdapter(fetch),
};
const SecurityApi = setupApi('SecurityApi', apiDescription, options);

SecurityApi.utils = {};

SecurityApi.utils.mapDispatchToProps = SecurityApi.actionCreators;

module.exports = SecurityApi;
