import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { apm } from '@elastic/apm-rum';
import { data as uxdata } from '@ux/data';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@ux/button';
import AlertIcon from '@ux/icon/alert';

import intents from '../common/intents-config';
import Track, { events } from './track';

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: props.__storybookProps?.hasError ?? false };
  }

  static defaultProps = {
    fallback: (
      <div className="container d-flex justify-content-center align-items-center">
        <AlertIcon
          color={'red'}
          width={48}
          height={48}
          data-testid="alretIcon"
        />
        <p className="mt-4 mb-4 leading-1">
          <FormattedMessage id="common__an_error_occured" />
        </p>
      </div>
    ),
  };

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error) {
    console.error(error);
    apm.captureError(error);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>{this.props.fallback}</>;
    }

    return this.props.children;
  }
}

const CustomError = ({
  customTitleId,
  customSubtitleId,
  customIconColor,
  statusCode,
  showSupportButton = false,
  showTryAgainButton = false,
  showBackToHomeButton = true,
  showBackToProductsButton = false,
}) => {
  const intl = useIntl();

  const titleId = customTitleId ? customTitleId : 'common__an_error_occured';
  const iconColor = customIconColor
    ? customIconColor
    : intents.ux.actionCritical.backgroundColor;

  /**
   * Sends users to a page where they can fill out a ticket for support
   */
  const redirectToSupport = () => {
    window.location.assign(`/_redirect/support/new`);
  };

  /**
   * Sends users My Products page for full list of their products
   */
  const redirectToMyProducts = () => {
    const rootURL = uxdata.urls.home.href;
    const productsURL = rootURL.replace('www.', 'account.') + '/products';

    window.location.assign(productsURL);
  };

  return (
    <div
      className="container d-flex justify-content-center"
      data-testid="error"
    >
      <div className="bg-white shadow-1 rounded col-sm-10 mt-5 mb-4 justify-content-center text-center flex-column p-4 error">
        <div id="custom-error" className="p-2">
          <AlertIcon
            color={iconColor}
            width={70}
            height={70}
            data-testid="alertIcon"
          />
          <h2 className="mt-4 mb-4 leading-1">
            <FormattedMessage id={titleId} />
          </h2>
          {customSubtitleId && (
            <h4 className="mt-4 mb-4 leading-1">
              <FormattedMessage id={customSubtitleId} />
            </h4>
          )}
          {statusCode && (
            <div className="mt-4 px-5">
              <p className="mb-4 mt-4 p-base">
                <FormattedMessage id="common_error_code" />: {statusCode}
              </p>
            </div>
          )}
          <div className="d-flex flex-row justify-content-center mt-5">
            {showTryAgainButton && (
              <div className="d-flex mr-4">
                <Track id={events.MANAGE_REDIRECT_TRY_AGAIN}>
                  <Button
                    id="tryagain"
                    design="primary"
                    type="submit"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={1}
                    onClick={() => location.reload(true)}
                    text={intl.formatMessage({ id: 'try_again' })}
                  />
                </Track>
              </div>
            )}
            {showSupportButton && (
              <div className="d-flex mr-4">
                <Track id={events.MANAGE_REDIRECT_SUPPORT}>
                  <Button
                    id="managesupport"
                    design="primary"
                    type="submit"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={1}
                    onClick={redirectToSupport}
                    text={intl.formatMessage({
                      id: 'wss_setup_success_base__request_support',
                    })}
                  />
                </Track>
              </div>
            )}
            {showBackToProductsButton && (
              <div className="d-flex mr-4">
                <Track id={events.MANAGE_REDIRECT_BACK_TO_PRODUCTS}>
                  <Button
                    design="secondary"
                    id="manageproducts"
                    onClick={redirectToMyProducts}
                    text={intl.formatMessage({
                      id: 'common_go_back_my_products',
                    })}
                  />
                </Track>
              </div>
            )}
            {showBackToHomeButton && (
              <div className="d-flex">
                <Track id={events.MANAGE_REDIRECT_BACK_TO_HOME}>
                  <Button
                    role="button"
                    id="managehome"
                    design="secondary"
                    href="/"
                    text={intl.formatMessage({ id: 'common_go_back_home' })}
                  />
                </Track>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CustomError.propTypes = {
  statusCode: PropTypes.number,
  customTitleId: PropTypes.string,
  customSubtitleId: PropTypes.string,
  customIconColor: PropTypes.string,
  showSupportButton: PropTypes.bool,
  showTryAgainButton: PropTypes.bool,
  showBackToHomeButton: PropTypes.bool,
  showBackToProductsButton: PropTypes.bool,
};

export default CustomError;
