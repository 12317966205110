import React from 'react';
import PropTypes from 'prop-types';

import { events, eventNames, getEid } from '../helpers/analytics';
import { logger } from '../common/initAPM';

const Track = ({
  children,
  id,
  desc = 'click',
  events = 'onClick',
  data = {},
  delay = 50,
}) => {
  if (!eventNames.has(id)) {
    logger.error(`${id} is an invalid event id`);
  }

  const getHandlers = (props) => {
    const handlers = {};

    events.split(',').map((eventName) => {
      eventName = eventName.trim();

      handlers[eventName] = (e) => {
        if (eventNames.has(id)) {
          // Delay calling the original event handlers for a tiny amount of time.
          // If the original event triggers navigation, the request to log the event
          // will be canceled and the event will never be recorded. Developers can
          // override the delay by passing another value (or zero) as the `delay` prop
          setTimeout(() => {
            if (typeof props[eventName] === 'function') {
              props[eventName](e);
            }
          }, delay);

          // replaces react-trfq usage, which depreciated and was removed from uxcore bundle
          window?._expDataLayer?.push({
            schema: 'add_event',
            version: 'v2',
            data: {
              eid: getEid(id),
              type: desc,
              event_type: e,
              custom_properties: {
                ...data,
              },
            },
          });
        }
      };
    });

    return handlers;
  };

  return (
    <>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          ...child.props,
          ...getHandlers(child.props),
        });
      })}
    </>
  );
};
Track.propTypes = {
  children: PropTypes.node,
  id: PropTypes.string.isRequired,
  events: PropTypes.string,
  desc: PropTypes.string,
  data: PropTypes.object,
  callback: PropTypes.func,
  delay: PropTypes.number,
};

export default Track;
export { events };
