// prettier-ignore
/* eslint-disable id-length */

module.exports = {
  BREADCRUMB_LINK_CLICK: 'breadcrumb.link.click',
  INACTIVEPLANS_SETUP_CLICK: 'inactiveplans.setup.click',
  MYSITES_SITECARD_PRODUCT_CLICK: 'mysites.sitecard_product.click',
  MYSITES_SITECARD_BACKUPS_CLICK: 'mysites.sitecard_backups.click',
  MYSITES_SITECARD_SETTINGS: 'mysites.sitecard.settings',
  MYSITES_SITECARD_SUPPORT_WAF_LEARN: 'mysites.sitecard.support_waf_learn',
  MYSITES_SITECARD_SUPPORT_WAF_REQUEST: 'mysites.sitecard.support_waf_request',
  MYSITES_BUY_NOW_BUTTON_CLICK: 'mysites.buy_now_button.click',
  MYSITES_SEARCH_FOCUS: 'mysites.search.focus',
  MYSITES_SORT_CHANGE: 'mysites.sort.change',
  MYSITES_MSSL_SETUP_START: 'mysites.mssl.setup.start',
  MYSITES_MSSL_SETUP_COMPLETED: 'mysites.mssl.setup.completed',
  MYSITES_MSSL_SETUP_FAILED: 'mysites.mssl.setup.failed',
  MYSITES_MSSL_SETUP_HELP_THIRD_PARTY: 'mysites.mssl.setup.help_third_party',
  MYSITES_MSSL_SETUP_HELP_CPANEL: 'mysites.mssl.setup.help_cpanel',
  MYSITES_SITECARD_NEXTSTEP_CLICK: 'mysites.sitecard.nextstep.click',
  MYSITES_SITECARD_NEXTSTEP_BACKUPS_CLICK: 'mysites.sitecard.nextstep_backups.click',
  MYSITES_SITECARD_BUY_PRODUCT_CLICK: 'mysites.sitecard.buy.click',
  MYSITES_SITECARD_REQUEST_CLEANUP_CLICK: 'mysites.sitecard.request_cleanup.click',
  MYSITES_WSS_SETUP_FAILED: 'wss_setup.provision.failed',
  MYSITES_WSS_SETUP_FAILED_NO_UID: 'wss_setup.provision.failed_no_uid',
  MYSITES_WSS_SETUP_FAILED_PROVISION: 'wss_setup.provision.failed_provision',
  MYSITES_WSS_SETUP_FAILED_MULTISITE_PROVISION: 'wss_setup.provision.failed_multisite_provision',
  MYSITES_WSS_SETUP_FAILED_WAF_UNKNOWN: 'wss_setup.provision.failed_waf_unknown',
  MYSITES_WSS_SETUP_FAILED_WAF_ERROR: 'wss_setup.provision.failed_waf_error',
  MYSITES_WSS_SETUP_FAILED_SITE_SCAN: 'wss_setup.provision.failed_site_scan',
  MYSITES_WSS_SETUP_PROVISION_SUCCESS: 'wp.onboarding.security-provision-complete/dashboard.button.click',
  MYSITES_WSS_SETUP_REDIRECT_STARTED: 'wss_setup.redirect.started',
  MYSITES_WSS_SETUP_REDIRECT_FAILED: 'wss_setup.redirect.failed',
  MYSITES_WSS_SETUP_DOMAIN_CONNECT_REDIRECT_STARTED: 'wss_setup.domainconnect.redirect.started',
  MYSITES_WSS_SETUP_DOMAIN_CONNECT_REDIRECT_FAILED: 'wss_setup.domainconnect.redirect.failed',
  MYSITES_WSS_SETUP_DOMAIN_CONNECT_MODAL_REDIRECT_STARTED: 'wss_setup.domainconnect.modal.redirect.started',
  MYSITES_WSS_SETUP_DOMAIN_CONNECT_MODAL_CANCELED: 'wss_setup.domainconnect.modal.canceled',
  MYSITES_WSS_SETUP_DOMAIN_CONNECT_MODAL_INIT: 'wss_setup.domainconnect.modal.init',
  PLANSETTINGS_MODAL_CLOSE: 'plansettings.modal.close',
  PLANSETTINGS_MODAL_REMOVE: 'plansettings.modal.remove',
  WSS_SETUP_BUTTON_CLICK: 'wss_setup.button.click',
  WSS_SETUP_MODAL_NEXT: 'wss_setup.modal.next',
  WSS_SETUP_MODAL_CLOSE: 'wss_setup.modal.close',
  WSS_SETUP_MODAL_RETRY: 'wss_setup.modal.retry',
  WSS_SETUP_MODAL_STARTED: 'wss_setup.modal.started',
  WSS_SETUP_MODAL_FINISHED: 'wss_setup.modal.finished',
  WSS_SETUP_MODAL_SUPPORT: 'wss_setup.modal.support',
  WSS_SETUP_MODAL_SUPPORT_WAF_REQUEST: 'wss_setup.modal.support_waf_request',
  WSS_SETUP_MODAL_SUPPORT_WAF_LEARN: 'wss_setup.modal.support_waf_learn',
  WSS_SETUP_MODAL_SUPPORT_WAF_CLOSE: 'wss_setup.modal.support_waf_close',
  WSS_SETUP_MODAL_SUPPORT_BACKUP_REQUEST: 'wss_setup.modal.support_backup_request',
  WSS_SETUP_MODAL_SUPPORT_BACKUP_LEARN: 'wss_setup.modal.support_backup_learn',
  WSS_SETUP_MODAL_SUPPORT_BACKUP_CLOSE: 'wss_setup.modal.support_backup_close',
  WSS_SETUP_MODAL_SUPPORT_PROVISIONING: 'wss_setup.modal.support_provisioning',
  WSS_SETUP_MODAL_SUCCESS: 'wss_waf_autoactivate',
  WSS_SETUP_MODAL_CAROUSEL_NEXT_CLICK: 'wss_setup.modal.carousel.next',
  WSS_SETUP_MODAL_CAROUSEL_PREVIOUS_CLICK: 'wss_setup.modal.carousel.previous',
  WSS_SETUP_MODAL_CAROUSEL_ENGAGEMENT: 'wss_setup.modal.carousel.engagement',
  WSS_SETUP_MODAL_WELCOME_CONTINUE_CLICK: 'wss_setup.modal.welcome_continue.click',
  WSS_SETUP_MODAL_WELCOME_EXIT_CLICK: 'wss_setup.modal.welcome_exit.click',
  WSS_SETUP_MODAL_WELCOME_HELPFUL_CLICK: 'wss_setup.modal.welcome_helpful.click',
  WSS_SETUP_MODAL_WELCOME_HELP_CLICK: 'wss_setup.modal.welcome_help.click',
  WSS_SETUP_MODAL_BACKUP_SITE_ACTIVATED: 'wss_setup.modal.backup_site_activated',
  WSS_SETUP_MODAL_BACKUP_SITE_ACTIVATION_FAILED: 'wss_setup.modal.backup_site_activation_failed',
  WSS_SETUP_MODAL_BACKUP_SITE_CONFIGURE: 'wss_setup.modal.backup_site_configure',
  WSS_SETUP_MODAL_FIRST_BACKUP_SUCCESSFUL: 'wss_setup.modal.first_backup_successful',
  WSS_SETUP_MODAL_FIRST_BACKUP_FAILED: 'wss_setup.modal.first_backup_failed',
  WSS_SETUP_MODAL_SUPPORT_BACKUPS_REQUEST: 'wss_setup.modal.support_backups_request',
  WSS_SETUP_MODAL_SUPPORT_BACKUPS_CLOSE: 'wss_setup.modal.support_backups_close',
  WSS_SETUP_MODAL_SUPPORT_SAVE_SFTP_CLICK: 'wss_setup.modal.support_backups_save_sftp.click',
  WSS_SETUP_MODAL_SUPPORT_SKIP_SFTP_CLICK: 'wss_setup.modal.support_backups_skip_sftp.click',
  WSS_SETUP_MODAL_SUPPORT_SAVE_DB_CLICK: 'wss_setup.modal.support_backups_save_db.click',
  WSS_SETUP_MODAL_SUPPORT_SKIP_DB_CLICK: 'wss_setup.modal.support_backups_skip_db.click',
  WSS_SETUP_MODAL_SUPPORT_SAVE_KEY_CLICK: 'wss_setup.modal.support_backups_save_key.click',
  WSS_SETUP_MODAL_SUPPORT_SKIP_KEY_CLICK: 'wss_setup.modal.support_backups_skip_key.click',
  WSS_SETUP_MODAL_SUPPORT_CREATE_BACKUP_CLICK: 'wss_setup.modal.support_backups_create_backup.click',
  WSS_SETUP_MODAL_SUPPORT_CLOSE_CREATE_BACKUP_CLICK: 'wss_setup.modal.support_backups_close_create_backup.click',
  WSS_SETUP_FAILED_SITE_EXISTS: 'wss_setup.failed.site_exists',
  WSS_SETUP_FAILED_SUBSCRIPTION_IN_USE: 'wss_setup.failed.subscription_in_use',
  WSS_SETUP_FAILED_INVALID_DOMAIN: 'wss_setup.failed.invalid_domain',
  WSS_SETUP_FAILED_INVALID_SUBSCRIPTION: 'wss_setup.failed.invalid_subscription',
  WSS_SETUP_FAILED_INVALID_BACKUPS: 'wss_setup.failed.invalid_backups',
  WSS_SETUP_FAILED_ERROR_RETURNED: 'wss_setup.failed.error_returned',
  WSS_SETUP_FAILED_ERROR_DISPLAYED: 'wss_setup.failed.error_displayed',
  WSS_SETUP_FAILED_UNKNOWN: 'wss_setup.failed.unknown',
  MULTISITE_PLAN_SETUP_CLICK: 'wss_setup.multisite_plan.setup.click',
  MULTISITE_PLAN_ADD_CLICK: 'wss_setup.multisite_plan.add.click',
  MANAGE_REDIRECT_TRY_AGAIN: 'manage.button.try_again.click',
  MANAGE_REDIRECT_SUPPORT: 'manage.button.support.click',
  MANAGE_REDIRECT_BACK_TO_HOME: 'manage.button.back_home.click',
  MANAGE_REDIRECT_BACK_TO_PRODUCTS: 'manage.button.back_products.click',
  WSS_GLOBAL_SUPPORT_BUTTON_CLICK: 'wss_global.support_button.click',
  BACKUPS_ERROR_VIEWED: 'backups.error.viewed',
  BACKUPS_ERROR_NEW_VIEWED: 'backups.error_new.viewed',
  BACKUPS_ERROR_SETTINGS_CLICK: 'backups.error.settings',
  BACKUPS_ERROR_FTP_LINK: 'backups.error_ftp.link',
  BACKUPS_ERROR_REDIRECT_LINK: 'backups.error_redirect.link',
  BACKUPS_ERROR_MWP_LINK: 'backups.error_mwp.link',
  BACKUPS_ERROR_EXCLUDE_LINK: 'backups.error_exclude.link',
  BACKUPS_ERROR_CONFIGURE_LINK: 'backups.error_configure.link',
  SECUI_MYSITES_BUY_NOW_BTN_CLICK:'sec.secui.mysites.buy_now_button.click'
};
