const { configureMakeStore, getOrCreateStore } = require('@gasket/redux');
const { HYDRATE, createWrapper } = require('next-redux-wrapper');
const merge = require('lodash.merge');

const SecurityApi = require('./common/redux/SecurityApi');
const generalReducers = require('./common/redux/reducers/general');

const rootReducer = (state, { type, payload }) =>
  type === HYDRATE ? merge({}, state, payload) : state;
const reducers = {
  ...SecurityApi.reducers,
  generalReducers,
  hivemind: (f) => f || null,
};
const makeStore = configureMakeStore({ rootReducer, reducers });
const nextRedux = createWrapper(getOrCreateStore(makeStore));

module.exports = makeStore;
module.exports.nextRedux = nextRedux;
