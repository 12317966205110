import { useEffect, useState } from 'react';
import axios from 'axios';
import { data as uxdata } from '@ux/data';

import { isEmpty } from '../../common/helpers';
import { determineBffURL } from '../../packages/environment-utils';

const useData = ({
  endpoint,
  action,
  params,
  initialValue,
  outputFilter,
  skip = false,
}) => {
  params = params || {};
  outputFilter =
    outputFilter ||
    function (o) {
      return o;
    };

  if (endpoint.isCached(params)) {
    const cached = endpoint.readCache(params);
    initialValue = cached.loading ? initialValue : cached.data;
  }

  const [data, setData] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (skip) {
      return;
    }

    if (!isEmpty(initialValue)) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const unsubscribe = endpoint.subscribe(
      { ...params },
      ({ data, loading, error }) => {
        setData(data);
        setLoading(loading);
        setError(error);
      },
    );

    endpoint[action](params);

    return unsubscribe;
  }, []);

  const invalidateCache = () => {
    endpoint.invalidate(params);
  };

  return {
    data: outputFilter(data),
    loading,
    error,
    invalidateCache,
  };
};

/**
 * Fetch CSRF token from BFF
 * @returns {Promise<string>} CSRF token
 */
const getBffCsrfToken = async () => {
  const bffUrl = determineBffURL();
  try {
    const brand = uxdata?.privateLabelId || 1;

    // Sucuri (Using SecUI BFF api proxy)
    const isSucuri = parseInt(brand, 10) === 565123;

    const resp = await axios.get(
      isSucuri ? '/api/bff/csrf/token' : `${bffUrl}/api/csrf/token`,
      {
        withCredentials: true,
      },
    );
    return resp.data.token;
  } catch {
    throw new Error('Failed to fetch CSRF token from BFF');
  }
};

export { useData, getBffCsrfToken };
