const { SET_ALREADY_POPPED_SETUP_MODAL } = require('../actions');

const defaultState = {
  alreadyPoppedSetupModal: false,
};

module.exports = function generalReducer(
  currentState = defaultState,
  { type },
) {
  switch (type) {
    case SET_ALREADY_POPPED_SETUP_MODAL:
      return {
        ...currentState,
        alreadyPoppedSetupModal: true,
      };
    default:
      return currentState;
  }
};
