const punycode = require('punycode/');

/**
 * Checks if value is an empty object, array or string
 * @param {any} value The value to check
 *
 * @returns {Boolean}
 */

const isEmpty = (value) => {
  if (value === null || typeof value === 'undefined') {
    return true;
  }

  // WS-8707: if the value is a boolean, it is NOT empty
  if (value.constructor === Boolean) {
    return false;
  }

  if (value.constructor === Object) {
    return Object.entries(value).length === 0;
  }

  if (
    value.constructor === Array ||
    value.constructor === String ||
    Object.prototype.hasOwnProperty.call(value, 'length')
  ) {
    return value.length === 0;
  }

  return true;
};

/**
 * Sorts an array of objects using the provided key
 * @param {Array} sortArray Array of objects
 * @param {String} sortByKey The key to sort by
 *
 * @returns { Array } - The sorted array
 */
const sortBy = (sortArray, sortByKey) => {
  const compare = (key) => {
    // eslint-disable-next-line no-nested-ternary
    return (a, b) => (a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0);
  };

  return sortArray.concat().sort(compare(sortByKey));
};

/**
 * Check if domain is the system generated like *..myftpupload.com
 *
 * @param {string} domain - The domain name
 * @returns {boolean} -
 */
const isSystemGenDomain = (domain) => {
  const isSysGen = /(^|^[^:]+:\/\/|[^.]+\.)myftpupload\.com/g;

  return domain && domain.match(isSysGen);
};

/**
 * Get domain|s of a subscription
 *
 * @param {object} subscription - The subscription object
 * @returns {array} - Array of domain names
 */
const getSubscriptionDomains = (subscription) => {
  if (
    (!subscription.label && !subscription.domain) ||
    subscription.label === 'New Account'
  ) {
    return [];
  }

  const domain = (subscription.domain || subscription.label).toLowerCase();
  let domains = [];

  if (domain.includes(',')) {
    domains = domain.replace(/\s/g, '').split(',');
  }

  if (domains.length > 1) {
    return domains.filter((d) => !isSystemGenDomain(d));
  }

  return [domain];
};

/**
 * Parse and normalize a domain name
 *
 * @param {string} domain - The domain name
 * @returns {string} - The normalized domain name
 */
const normalizeDomainName = (domain = '') => {
  return punycode
    .toUnicode(domain)
    .replace(/\s/g, '')
    .replace(/\*\./g, '')
    .replace(/http:\/\//g, '')
    .replace(/https:\/\//g, '')
    .replace(/www\./g, '');
};

/**
 * Check if a variable is a true number or not (thanks to JS math skills)
 *
 * @param {any} num The value to check
 * @returns {boolean} - if the value passed is a number or not
 */
const isNumeric = (num) =>
  (typeof num === 'number' || (typeof num === 'string' && num.trim() !== '')) &&
  !isNaN(num);

const getCookies = function () {
  var pairs = document.cookie.split(';');
  var cookies = {};
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split('=');
    cookies[(pair[0] + '').trim()] = unescape(pair.slice(1).join('='));
  }
  return cookies;
};

/**
 * First attempts to parse the cookie as JSON. If the parsing is successful,
 * it checks if the resulting object has a property named 'e2s'. If it does, the function
 * returns true, indicating that the cookie is an impersonation cookie.
 *
 * If the cookie cannot be parsed as JSON, the function attempts to decode it using
 * decodeURIComponent before parsing it again and doing the same check
 *
 * If neither attempt is successful, the function returns false.
 *
 * @param {string} cookie - The cookie to check.
 * @returns {boolean|null} True if the cookie is an impersonation cookie, false if it is not,
 * or null if no cookie was provided.
 */
const isImpersonationCookie = function (cookie) {
  // Check if the cookie is a valid JSON and check if it has the e2s key
  try {
    const idpInfo = JSON.parse(cookie);
    return 'e2s' in idpInfo;
  } catch (e) {
    // Do nothing
  }
  // If the cookie is not valid JSON,Decode URI and check if it has the e2s key
  try {
    const idpInfo = JSON.parse(decodeURIComponent(cookie));
    return 'e2s' in idpInfo;
  } catch (e) {
    // Do nothing
  }

  // If the cookie couldn't be parsed as JSON or isn't an Impersonation Cookie
  return false;
};

const isImpersonation = () => {
  const cookies = getCookies();
  const info = cookies?.info_idp;
  return isImpersonationCookie(info);
};

/**
 * Format a display date for MSSL
 *
 * @param {string} createdDate raw date
 * @param {object} intl internationalization
 * @returns {string} - formatted created date string
 */
const dateCreatedDisplay = (createdDate, intl) => {
  const today = new Date();
  const created = new Date(createdDate);
  const yesterday = new Date();
  let returnString = '';
  yesterday.setDate(yesterday.getDate() - 1);
  if (today.getDate() === created.getDate()) {
    returnString = intl.formatMessage({ id: 'today' });
  } else if (yesterday.getDate() === created.getDate()) {
    returnString = intl.formatMessage({ id: 'yesterday' });
  } else {
    returnString = created.toLocaleDateString();
  }
  return returnString;
};

/**
 * Converts a comma-separated string list into an array of strings.
 *
 * @param {string} value - The comma-separated string list to convert.
 * @returns {string[]} - The array of strings.
 */
const getArrayFromStrList = (value) => {
  if (!value || typeof value !== 'string') return [];

  return value.split(',');
};

/**
 * Gets the URL path with all query params including PLID.
 * It merges the search parameters of the current URL and the provided path.
 * If the window object is undefined or current URL has no search params, it returns the provided path as is.
 *
 * @param {string} path - The path.
 * @returns {string} - The URL path with query params.
 */
const getURLPathWithQueryParams = (path) => {
  if (typeof window === 'undefined' || !window.location?.search) return path;

  const currentParams = new URLSearchParams(window.location.search);
  const newUrl = new URL(path, window.location.origin);

  const allParams = new URLSearchParams({
    ...Object.fromEntries(currentParams),
    ...Object.fromEntries(newUrl.searchParams),
  });

  return `${newUrl.pathname}?${allParams.toString()}`;
};

/**
 * Uppercase the first character of a string
 * @param {string} s word in which you want to uppercase the first character
 * @returns {string} - The string with the first character in uppercase
 */
const firstCharToUpperCase = (s) => {
  return `${s.charAt(0).toUpperCase()}${s.slice(1)}`;
};

module.exports = {
  isEmpty,
  sortBy,
  isNumeric,
  getSubscriptionDomains,
  isSystemGenDomain,
  normalizeDomainName,
  dateCreatedDisplay,
  isImpersonation,
  getArrayFromStrList,
  getURLPathWithQueryParams,
  firstCharToUpperCase,
};
